import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="nav"
export default class extends Controller {
  static values = { state: Boolean };
  static targets = ["nav"];

  connect() {
    console.log("Nav controller connected");
    document.addEventListener("keydown", this.handleKeydown.bind(this));
    this.navTarget.addEventListener(
      "animationend",
      this.handleAnimationEnd.bind(this),
    );
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown.bind(this));
    this.navTarget.removeEventListener("animationend", this.handleAnimationEnd);
  }

  handleAnimationEnd() {
    if (!this.stateValue) this.navTarget.classList.add("hidden");
  }

  handleKeydown(event) {
    if (event.key === "Escape" && this.stateValue) {
      this.hideModal();
    }
  }

  toggleNav() {
    this.stateValue ? this.closeNav() : this.openNav();
  }

  openNav() {
    this.updateState(true);
    this.navTarget.classList.remove("slide--out", "hidden");
    this.navTarget.classList.add("slide--in", "flex");
    this.navTarget.removeAttribute("inert");

    document.body.classList.add("modal--open");

    // Set focus after the modal has been shown
    setTimeout(() => this.navTarget.focus(), 10);
  }

  closeNav() {
    this.navTarget.classList.remove("slide--in", "flex");
    this.navTarget.classList.add("slide--out", "hidden");
    this.navTarget.setAttribute("inert", "true");

    // Wait for the slide-out animation to complete before hiding
    this.navTarget.addEventListener(
      "animationend",
      () => {
        if (!this.stateValue) this.navTarget.classList.add("hidden");
      },
      { once: true },
    );

    document.body.classList.remove("modal--open");

    // Return focus to the button or another safe element
    const toggleButton = this.element.querySelector(
      "[data-action='click->nav#toggleNav']",
    );
    toggleButton?.focus();
  }

  hideModal() {
    this.updateState(false);
    this.closeNav();
  }

  updateState(isOpen) {
    this.stateValue = isOpen;
    if (isOpen) {
      this.navTarget.classList.remove("hidden");
    }
  }
}
