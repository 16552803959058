import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="theme"
export default class extends Controller {
  static targets = ["toggle"];

  connect() {
    console.log("Theme controller connected");
    console.log(this.toggleTarget);
  }

  toggleTheme() {
    document.documentElement.classList.toggle("dark");
  }
}
