import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="info"
export default class extends Controller {
  static values = { state: Boolean };
  static targets = ["info"];

  connect() {
    console.log("Info controller connected");
    document.addEventListener("keydown", this.handleKeydown.bind(this));
    this.infoTarget.addEventListener(
      "animationend",
      this.handleAnimationEnd.bind(this),
    );
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown.bind(this));
    this.infoTarget.removeEventListener(
      "animationend",
      this.handleAnimationEnd,
    );
  }

  handleAnimationEnd() {
    if (!this.stateValue) this.infoTarget.classList.add("hidden");
  }

  handleKeydown(event) {
    if (event.key === "Escape" && this.stateValue) {
      this.hideModal();
    }
  }

  toggleInfo() {
    this.stateValue ? this.closeInfo() : this.openInfo();
  }

  openInfo() {
    this.updateState(true);
    this.infoTarget.classList.remove("slide--out", "hidden");
    this.infoTarget.classList.add("slide--in", "flex");
    this.infoTarget.removeAttribute("inert");

    document.body.classList.add("modal--open");

    // Set focus after the modal has been shown
    setTimeout(() => this.infoTarget.focus(), 10);
  }

  closeInfo() {
    this.infoTarget.classList.remove("slide--in", "flex");
    this.infoTarget.classList.add("slide--out", "hidden");
    this.infoTarget.setAttribute("inert", "true");

    // Wait for the slide-out animation to complete before hiding
    this.infoTarget.addEventListener(
      "animationend",
      () => {
        if (!this.stateValue) this.infoTarget.classList.add("hidden");
      },
      { once: true },
    );

    document.body.classList.remove("modal--open");

    // Return focus to the button or another safe element
    const toggleButton = this.element.querySelector(
      "[data-action='click->info#toggleInfo']",
    );
    toggleButton?.focus();
  }

  hideModal() {
    this.updateState(false);
    this.closeInfo();
  }

  updateState(isOpen) {
    this.stateValue = isOpen;
    if (isOpen) {
      this.infoTarget.classList.remove("hidden");
    }
  }
}
